@use '@angular/material' as mat;
@mixin app-side-nav-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);
  $nav-background-opacity: if($is-dark-theme, 0.2, 0.03);

  .app-side-navigation-content {
    background: rgba(0, 0, 0, $nav-background-opacity);

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.26);
    }

    a,
    button {
      color: mat.get-color-from-palette($foreground, secondary-text);

      &.app-side-navigation-item-selected {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}
