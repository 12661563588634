@mixin app-show-print-theme($theme) {
  $primary: #13c9d8;

  .print-tickets,
  .print-tickets-overview {
    background: white;
  }

  .print-tickets {
    .mat-h3,
    .mat-h5,
    .mat-h6 {
      font-weight: bold;
    }

    .mat-h5,
    .mat-h6 {
      color: $primary;
    }

    .mat-h3,
    .mat-body-strong {
      color: #4c4c4c;
    }

    .divider {
      width: 80px;
      margin-left: 16px;
      border-top: 3px solid $primary;
    }
  }

  .print-tickets-overview {
    font-size: 8pt;
  }
}
