$dts-primary: #7ddafc !default;

$select-box-color: $dts-primary !default;
$select-box-border-size: 2px !default;
$select-box-removing-color: $dts-primary !default;

$selected-item-border: true !default;
$selected-item-border-color: #d2d2d2 !default;
$selected-item-border-size: 1px !default;

$range-start-border: true !default;
$range-start-border-color: #2196f3 !default;

$box-shadow: true !default;
