@use '@angular/material' as mat;
@import 'assets/styles/charts';
@import 'app/layout/side-nav/side-nav-theme';
@import 'app/components/show-overview/show-print/show-print-theme';
@import 'app/components/chat/chat-notification/chat-notification-theme';
@import 'ngx-drag-to-select';

@include mat.core();

// material palettes
$app-primary-palette: mat.define-palette(mat.$cyan-palette);
$app-accent-palette: mat.define-palette(mat.$amber-palette);

// variables for both themes
$app-primary: mat.get-color-from-palette(mat.$cyan-palette, 500);
$app-accent: mat.get-color-from-palette(mat.$amber-palette, 500);
$app-warn: mat.get-color-from-palette(mat.$red-palette, 500);

$app-typography: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $button: mat.define-typography-level(14px, 14px, 500),
);

// light theme
$app-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $app-primary-palette,
      accent: $app-accent-palette,
    ),
  )
);
$app-background-light: map-get($app-theme-light, background);
$app-foreground-light: map-get($app-theme-light, foreground);

// dark theme
$app-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $app-primary-palette,
      accent: $app-accent-palette,
    ),
  )
);
$app-background-dark: map-get($app-theme-dark, background);
$app-foreground-dark: map-get($app-theme-dark, foreground);

@include mat.all-component-themes($app-theme-light);
@include mat.all-component-typographies($app-typography);

.app-light {
  background: mat.get-color-from-palette($app-background-light, background);
  @include app-side-nav-theme($app-theme-light);
  @include app-show-print-theme($app-theme-light);
  @include app-chat-notification-theme($app-theme-light);

  a {
    color: mat.get-color-from-palette($app-foreground-light, secondary-text);
  }

  tr.mat-mdc-row:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .mat-mdc-tab-list {
    background: #fafafa;
  }

  .mat-drawer-container .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.app-dark {
  background: mat.get-color-from-palette($app-background-dark, background);
  @include mat.all-component-colors($app-theme-dark);
  @include app-side-nav-theme($app-theme-dark);
  @include app-show-print-theme($app-theme-dark);
  @include app-chat-notification-theme($app-theme-dark);

  a {
    color: mat.get-color-from-palette($app-foreground-dark, secondary-text);
  }

  tr.mat-mdc-row:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  .mat-mdc-tab-list {
    background: #303030;
  }

  .mat-drawer-container .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(189, 189, 189, 0.3);
  }
}

// layout tweaks
app-navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  mat-toolbar.mat-toolbar {
    height: 64px;
  }
}

[mat-dialog-title] {
  display: flex !important;
  margin: 0 0 16px !important;
  padding: 10px 24px !important;
  background: $app-primary !important;
  color: #fff !important;

  button.mat-mdc-icon-button {
    &:hover {
      color: rgba(white, 0.75);
    }
  }

  &[cdkDrag] {
    cursor: move;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;

  > app-user-login-page,
  app-user-sign-up-page,
  app-user-forgot-password-page {
    height: 100vh;
  }

  > app-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    > mat-drawer-container {
      flex: 1;
    }
  }
}

// bootstrap tweaks
button,
textarea {
  &:focus {
    outline: 0 !important;
  }
}

input.form-control:focus {
  border-color: $app-primary;
  box-shadow: inset 0 1px 1px rgba($app-primary, 0.075), 0 0 8px rgba($app-primary, 0.6);
}

// apply styles for both themes
.app-light,
.app-dark {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 15px;

  a,
  button:not(.mat-mdc-button-base) {
    &:hover {
      text-decoration: none;
      color: $app-primary;
    }
  }

  .color-primary {
    color: $app-primary;
  }

  .color-primary-bg {
    background-color: $app-primary;
  }

  .router-active {
    background: white !important;
    color: $app-primary !important;

    .mat-mdc-button:hover {
      color: $app-primary !important;
    }
  }

  tr.mat-mdc-row {
    cursor: pointer;
  }

  .mat-mdc-menu-item-text {
    font-size: 14px !important;
  }

  .mat-mdc-checkbox label,
  .mat-mdc-slide-toggle label {
    margin-bottom: 0px;
  }

  // remove border and box shadow of selected seats (drag to select)
  .dts-select-item {
    border: 0 !important;
    box-shadow: none !important;
  }
}
