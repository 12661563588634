@use '@angular/material' as mat;
@mixin app-chat-notification-theme($theme) {
  $primary: map-get($theme, primary);

  .chat-notification {
    .author {
      color: mat.get-color-from-palette($primary);
    }
  }
}
